import { useState, useRef, useEffect } from "react";
const useWindowWidth = () => {
  return useDebouncedWindowWidth(0);
};
const useDebouncedWindowWidth = (delay) => {
  const [windowWidth, setWindowWidth] = useState(0);
  useDebouncedResizeHandler(delay, setWindowWidth);
  return windowWidth;
};
const useDebouncedResizeHandler = (delay, callback) => {
  const timeoutRef = useRef(null);
  useEffect(() => {
    callback(window.innerWidth);
  }, [callback]);
  useEffect(() => {
    const handleResize = () => {
      const timeout = timeoutRef.current;
      if (timeout) {
        clearTimeout(timeout);
      }
      timeoutRef.current = setTimeout(() => {
        callback(window.innerWidth);
      }, delay);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [delay, callback]);
};
export {
  useDebouncedResizeHandler,
  useDebouncedWindowWidth,
  useWindowWidth
};
